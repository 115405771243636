import { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [, setMessageConfirmed] = useState(undefined);
  const [resolveConfirmation, setResolveConfirmation] = useState(undefined);

  /**
   * Agrega un mensaje al contexto de mensajes.
   * @param {string} type - El tipo de mensaje.
   * @param {string} header - El encabezado del mensaje.
   * @param {string} message - El contenido del mensaje.
   * @returns {void}
   * @example
   * addMessage('success', 'Éxito', 'El producto se ha añadido correctamente.');
   * addMessage('error', 'Error', 'No se pudo añadir el producto.');
   * addMessage('warning', 'Advertencia', 'El producto ya está en el carrito.');
   * addMessage('info', 'Información', 'El producto se ha eliminado.');
   * addMessage('confirm', 'Confirmación', '¿Está seguro de eliminar el producto?');
   */
  const addMessage = (type, header, message) => {
    setMessages([...messages, { type, header, message }]);
  };

  /**
   * Elimina un mensaje del arreglo de mensajes.
   * @param {number} index - El índice del mensaje a eliminar.
   * @returns {void}
   * @example
   * removeMessage(0);
   * removeMessage(1);
   * removeMessage(2);
   */
  const removeMessage = (index) => {
    setMessages(messages.filter((_, i) => i !== index));
  };

  /**
   * Función que devuelve una promesa que se resuelve cuando se confirma un mensaje.
   * @returns {Promise<Function>} Una promesa que se resuelve con una función de resolución.
   * @example
   * const confirmation = confirmMessage();
   * confirmation.then((value) => console.log(value));
   * // "true"
   */
  const confirmMessage = () => {
    return new Promise((resolve) => {
      setResolveConfirmation(() => resolve);
    });
  };

  /**
   * Maneja la confirmación de un valor.
   * @param {any} value - El valor de confirmación.
   * @returns {void}
   * @example
   * handleConfirm(true);
   * handleConfirm(false);
   * handleConfirm(undefined);
   */
  const handleConfirm = (value) => {
    if (resolveConfirmation) {
      resolveConfirmation(value);
      setResolveConfirmation(undefined);
    };
    setMessageConfirmed(value);
  };

  return (
    <MessageContext.Provider value={{
      messages,
      addMessage,
      removeMessage,
      confirmMessage,
      handleConfirm
    }}>
      {children}
    </MessageContext.Provider>
  );
};
