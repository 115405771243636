import { useState, useEffect } from "react";
import { signInWompi } from "../utils";

/**
 * Hook personalizado para obtener la firma de Wompi basada en los detalles del pago.
 * @param {Object} paymentDetails - Los detalles del pago necesarios para la firma.
 * @returns {Object} - Un objeto que contiene la respuesta de la firma y un posible error.
 * @example
 * const { data, error } = useFetchSignature(paymentDetails);
 * console.log(data);
 * // => { data: "signature" }
 */
const useFetchSignature = (paymentDetails) => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const data = await signInWompi(paymentDetails);
        setData(data);
      } catch (err) {
        setError(err);
      };
    };

    if (paymentDetails) {
      fetchSignature();
    }
  }, [paymentDetails]);

  return { data, error };
};

export default useFetchSignature;
