import { useState, useEffect } from "react";
import { getMunicipalitiesCode } from "../utils";

/**
 * Hook personalizado para obtener y gestionar la lista de ciudades según el código de departamento.
 * @param {string} codigoDepartamento - El código del departamento seleccionado.
 * @param {Function} handleChange - Función para manejar el cambio de formulario.
 * @returns {Object} - Contiene la lista de ciudades, cualquier error, si está cargando, y el manejador de cambios combinados.
 * @example
 * const { cities, error, loading, handleChange } = useCities("05", handleDepartmentChange);
 * console.log(cities);
 * // => [{ Codigo_Municipio: "05001", Municipio: "MEDELLÍN" }, { Codigo_Municipio: "05002", Municipio: "ABEJORRAL" }]
 */
const useCities = (department) => {
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!department) return; 

    /**
     * Función asincrónica que obtiene una lista de ciudades modificada a partir del código del departamento.
     * @returns {Promise<void>} No devuelve ningún valor directamente.
     * @throws {Error} Si ocurre un error al obtener los municipios.
     * @async
     * @example
     * fetchCities();
     * console.log(cities);
     * // => [{ Codigo_Municipio: "05001", Municipio: "MEDELLÍN" }, { Codigo_Municipio: "05002", Municipio: "ABEJORRAL" }]
     */
    const fetchCities = async () => {
      try {
        setLoading(true);
        const  data  = await getMunicipalitiesCode(department);
        setCities(data);
        setLoading(false);
      } catch (error) {
        setError("Error al obtener los municipios");
        setLoading(false);
      }
    };

    fetchCities();
  }, [department]);

  return { cities, error, loading };
};

export default useCities;
