import { useState, useEffect } from "react";
import { getMunicipalities,  } from "../utils";

/**
 * Hook personalizado para obtener y gestionar la lista de departamentos.
 * @returns { Object } - Contiene la lista de departamentos, cualquier error, y si está cargando.
 * @example
 * const { departments, error, loading } = useDepartments();
 * console.log(departments);
 * // => [{ Codigo_Deapartamento: 1, Departamento: "Amazonas" }, { Codigo_Deapartamento: 2, Departamento: "Antioquia" }]
 */
const useDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    /**
     * Función asincrónica para obtener los departamentos.
     * @async
     * @function fetchDepartments
     * @returns {Promise<void>} No devuelve ningún valor directamente.
     * @throws {Error} Si ocurre un error al obtener los departamentos.
     * @example
     * fetchDepartments();
     * console.log(departments);
     * // => [{ Codigo_Deapartamento: 1, Departamento: "Amazonas" }, { Codigo_Deapartamento: 2, Departamento: " Antioquia" }]
     */
    const fetchDepartments = async () => {
      try {
        setLoading(true);
        const  data  = await getMunicipalities();
        setDepartments(data);
        setLoading(false);
      } catch (error) {
        setError("Error al obtener los departamentos");
        setLoading(false);
        console.error("Error al obtener los departamentos", error);
      };
    };

    fetchDepartments();
  }, []);

  return { departments, error, loading };
};

export default useDepartments;
