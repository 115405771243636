import React from "react";
import "./ScrollingText.css";

export const ScrollingText = () => {
  return (
    <div className="scrolling-text">
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en Instagram @toubelle_
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 😉 Síguenos en TikTok @toubelle_
      </span>
    </div>
  );
};
