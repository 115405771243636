import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Routers } from "./router/Routers";
import './App.css';

function App() {

  const gtmId = process.env.REACT_APP_GTM_ID;

  useEffect(() => {
    if (gtmId) {
      // Inicializa GTM solo si gtmId está definido
      TagManager.initialize({ gtmId });
    } else {
      console.warn("GTM ID no está definido en las variables de entorno.");
    }
  }, [gtmId]);
  
  return (
    <Routers/>  
  );
}

export default App;
