import { moneyFormat } from ".";

const shippingRates = {
  standard: {
    Local: {
      1: 7250,
      3: 9350,
      5: 13150,
      10: 17350,
      extra: 425,
    },
    Regional: {
      1: 8950,
      3: 11150,
      5: 15950,
      10: 21950,
      extra: 550,
    },
    Nacional: {
      1: 13950,
      3: 16350,
      5: 22950,
      10: 29950,
      extra: 850,
    },
    Zonal: {
      1: 17950,
      3: 22150,
      5: 30950,
      10: 46650,
      extra: 2225,
    },
    "Otros destinos": {
      1: 28950,
      3: 31750,
      5: 43950,
      10: 60950,
      extra: 2950,
    },
  },

  nextDay: {
    1: 8350,
    3: 10000,
    5: 14700,
    10: 18900,
    extra: 550,
  },

  currentDay: {
    1: 8900,
    3: 10960,
    5: 14960,
    10: 19900,
    extra: 550,
  },
};

/**
 * Calcula el costo de envío basado en la región, peso, tipo de envío y método de pago.
 * @param {string} region - La región a la que se enviará el paquete.
 * @param {number} weight - El peso del paquete en kilogramos.
 * @param {string} type - El tipo de envío (e.g., "standard", "express_tomorrow", "express_today").
 * @param {string} payment - El método de pago (e.g., "contraentrega").
 * @returns {number} - El costo de envío calculado. Retorna -1 si el pago es "contraentrega" o si la región o las tarifas no están definidas.
 * @example
 * const shippingCost = calculateShippingCost("Local", 2, "standard", "online");
 * console.log(shippingCost);
 * // 9350
 */
export const calculateShippingCost = (
  contraentrega,
  region,
  weight,
  type,
  payment,
  productTotal
) => {
  console.log(contraentrega, region, weight, type, payment, productTotal);
  const rates = shippingRates[type]?.[region] || shippingRates[type];

  if (!region || !rates) {
    return -1;
  }

  if (["nextDay", "currentDay"].includes(type) && region !== "Local") {
    return -3;
  }

  const weightBrackets = [1, 3, 5, 10]; // valida el peso de los productos
  const roundedWeight = Math.ceil(weight); // Redondear hacia arriba el peso

  // 0 - 1 kg, de 1 -- 3 kg, 3 - 5 kg , 5 - 10kg , 10 kg + monto por kg extra
  const shippingCost = weightBrackets.find(
    (bracket) => roundedWeight <= bracket
  );

  let finalShippingCost = rates[shippingCost];

  const applyCommission = (baseCost, rate, minCommission) => {
    let commission = productTotal * rate;
    return baseCost + Math.max(commission, minCommission);
  };

  if (payment === "contraentrega" && contraentrega === "true") {
    if (region === "Local") {
      finalShippingCost = applyCommission(finalShippingCost, 0.033, 3550);
      console.log("shippingCost local", finalShippingCost);
    } else {
      finalShippingCost = applyCommission(finalShippingCost, 0.039, 4250);
      console.log("shippingCost resto", finalShippingCost);
    }
  }

  return finalShippingCost;
};

/**
 * Muestra el costo de envío en un formato legible.
 * @returns {string} El costo de envío formateado o un mensaje correspondiente si el envío no está disponible, es contraentrega, o es gratis.
 * @example
 * const shippingValue = displayShippingValue(9350);
 * console.log(shippingValue);
 * // "$9.350"
 */
export const displayShippingValue = (shipping) => {
  switch (shipping) {
    case -3:
      return "No disponible";
    case -2:
      return "---";
    case 0:
      return "Gratis";
    default:
      return moneyFormat(Math.max(shipping, 0));
  }
};
