import { createPreOrder } from "./fetchs";

/**
 * Realiza una solicitud POST a la API de Melonn para crear una pre-orden.
 * @async
 * @param {Object} data - Los datos de la pre-orden.
 * @param {Array} data.products - Los productos de la pre-orden.
 * @param {Object} data.paymentDetails - Los detalles del pago.
 * @param {Object} data.signatureResponse - La respuesta de la firma.
 * @param {Object} data.shipping - El costo de envío.
 * @param {Object} data.shipmentData - Los datos de envío.
 * @param {Object} data.deliveryData - Los datos de entrega.
 * @param {Object} data.thirdPersonData - Los datos de la tercera persona.
 * @returns {Promise<any>} - Una promesa que se resuelve con la respuesta de la API de Melonn.
 * @example
 * const response = await preOrder({
 *  products: [{ clave1: "valor1" }],
 *  paymentDetails: { clave1: "valor1" },
 *  signatureResponse: { clave1: "valor1" },
 *  shipping: { clave1: "valor1" },
 *  shipmentData: { clave1: "valor1" },
 *  deliveryData: { clave1: "valor1" },
 *  thirdPersonData: { clave1: "valor1" },
 * });
 * console.log(response);
 * // => { clave1: "valor1" }
 */
export const preOrder = async ({
  products,
  paymentDetails,
  signatureResponse,
  shipping,
  shipmentData,
  deliveryData,
  thirdPersonData
}) => {
  const { reference } = signatureResponse;
  const {
    Correo,
    Celular,
    Direccion,
    Documento,
    Municipio,
    Departamento,
    Complemento_Direccion,
    Tipo_Envio,
    Forma_Pago,
  } = deliveryData;

  const {
    Nombre,
    Primer_Apellido,
    Segundo_Nombre,
    Segundo_Nombre1,
  } = shipmentData.user || {};

  const { selectedContributorType, selectedDocument, selectedThirdPartyRole } = thirdPersonData;
  const { coordi_code: codeDestination, postalCode } = Municipio;

  const order = {
    referenceEcommercePurchaseRecords: reference,
    statusOrderEcommercePurchaseRecords: "Pre-Orden",
    JsonOrder: JSON.stringify({
      data: {
        paymentData: {
          Reference: reference,
          domain: "toubelle.co", 
        },
        shippingData: {
          City: Municipio.Municipio,
          Department: Departamento.nombre,
          name: shipmentData.user ? `${Nombre} ${Primer_Apellido}` : `${deliveryData.Nombre} ${deliveryData.Primer_Apellido}`,
          address: shipmentData.user ? Direccion : deliveryData.Direccion,
          address2: Complemento_Direccion, 
          email: shipmentData.user ? Correo : deliveryData.Correo,
          requestProcessing: Tipo_Envio === "standard" ? false : true, 
          shippingMethodTitle: Tipo_Envio,
          DeliveryCost: shipping,
          paymentOnDelivery: {
            title: Forma_Pago === "contraentrega" ?  "paymentOnDelivery" : "", 
            amount: Forma_Pago === "contraentrega" ? paymentDetails.amount : 0
          },
          codeDestination,
          postalCode,
          phone: shipmentData.user ? Celular : deliveryData.Celular,
          country: "Colombia",
        },
        customerData: {
          idTerceroTipoIdentificacion: selectedDocument, 
          identificacion: Documento,
          primerNombre: shipmentData.user ? Nombre : deliveryData.Nombre,
          segundoNombre: shipmentData.user ? Segundo_Nombre || "" : deliveryData.Segundo_Nombre || "",
          primerApellido: shipmentData.user ? Primer_Apellido : deliveryData.Primer_Apellido,
          segundoApellido: shipmentData.user ? Segundo_Nombre1 || "" : deliveryData.Segundo_Apellido,
          idCiudad: deliveryData.Municipio.woCode,
          idTerceroTipos: selectedThirdPartyRole,
          idTerceroTipoContribuyente: selectedContributorType,
          idClasificacionImpuestos: selectedThirdPartyRole,
          direccion: shipmentData.user ? Direccion : deliveryData.Direccion,
          telefono: deliveryData.Celular,
          email: deliveryData.Correo,
          plazoDias: 1,
        },
        orderData: {
          items: products.map((product) => ({
            productID: product.id,
            sku: product.sku,
            codeLintegrateProducts: product.codeLintegrateProducts,
            quantity: product.quantity,
            price: product.price,
            total: product.price * product.quantity,
          })),
          total: paymentDetails.amount,
        },
      },
    }),
  };
  console.log("Order: ", order);
  try {
    const { JsonOrder: JsonOrderEcommercePurchaseRecords, ...rest } = order;
    const response = await createPreOrder({
      ...rest,
      JsonOrderEcommercePurchaseRecords,
    });
    console.log("Data response: ", response);
    return response.validation
  } catch (error) {
    console.error("Error al generar la pre-orden", error);
  };
};