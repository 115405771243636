import React, { useEffect, useContext } from "react";
import { useDepartments, useCities } from "../../hooks";
import { handleChange, calculateShippingCost } from "../../utils";
import { CartContext } from "../../contexts/ShoppingCartContext";
import wompiIcon from "../../assets/images/icons/wompi-icon.png";
import "./shippingForm.css";

const Shippingform = ({
  title,
  enableShipmentType = false,
  enablePayment = false,
  data = {},
  setData = () => {},
}) => {
  const { allProducts, setShipping, total } = useContext(CartContext);
  const { departments, isLoading: isLoadingDepartments } = useDepartments();
  const { cities, isLoading: isLoadingCities } = useCities(
    data?.Codigo_Deapartamento
  );
  const onChange = handleChange(data, setData);

  /**
   * Calcula el costo basado en la cantidad de productos y el peso total.
   * @param {number} total - La cantidad total de productos.
   * @returns {number} El costo total, donde los primeros 1-3 productos tienen un costo de 1, y cada producto adicional añade 0.5.
   * @example
   * const total = 5;
   * const shippingCost = calculateShippingCost(total);
   * console.log(shippingCost);
   * // 3.5
   */
  function multiplicarConMinimo(total) {
    return 1 + Math.max(total - 3, 0) * 0.5;
  }

  useEffect(() => {
    const {Cobertura, Tipo_Envio, Forma_Pago} = data;

    if (!Cobertura || !Forma_Pago || !Tipo_Envio) {
      return;
    }

    const totalProductos = allProducts.reduce(
      (acc, producto) => acc + (producto.quantity || 0),
      0
    );
    const weight = multiplicarConMinimo(totalProductos);
    const shippingCost = calculateShippingCost(
      data.Municipio?.coberturaMelonnContraentrega,
      Cobertura,
      weight,
      Tipo_Envio,
      data.Forma_Pago,
      total
    );
    setShipping(shippingCost);
  }, [allProducts, setShipping, data, total]);

  return (
    <>
      {title && <h2 className='subtitle'>{title}</h2>}
      <div className='form-row'>
        <div className='form-column'>
          <label htmlFor='correo'>Correo Electrónico</label>
          <input
            type='email'
            id='correo'
            name='Correo'
            placeholder='Eje. micorreo@dominio.com'
            value={data.Correo}
            onChange={onChange}
            required
          />
        </div>
        <div className='form-column'>
          <label htmlFor='telefono'>Teléfono</label>
          <input
            type='tel'
            id='telefono'
            name='Celular'
            maxLength={10}
            placeholder='Eje. 3001234567'
            value={data.Celular}
            onChange={onChange}
            required
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-column'>
          <label htmlFor='departamento'>Departamento</label>
          <select
            id='departamento'
            name='Codigo_Deapartamento'
            value={data.Codigo_Deapartamento}
            onChange={onChange}
            disabled={isLoadingDepartments}
            required>
            <option
              disabled
              hidden
              value=''>
              Seleccione un departamento
            </option>
            {departments.map(
              ({ Codigo_Deapartamento: codigo, Departamento: nombre }) => (
                <option
                  data-departamento={JSON.stringify({ codigo, nombre })}
                  key={`${codigo}-${nombre.replace("/s/g", "-")}`}
                  value={codigo}>
                  {nombre}
                </option>
              )
            )}
          </select>
        </div>
        <div className='form-column'>
          <label htmlFor='municipio'>Municipio </label>
          <select
            id='municipio'
            name='Codigo_Municipio'
            value={data.Codigo_Municipio}
            onChange={onChange}
            disabled={isLoadingCities}
            required>
            <option
              disabled
              hidden
              value=''>
              Seleccione un municipio
            </option>
            {cities.map((city) => (
              <option
                data-municipio={JSON.stringify(city)}
                data-cobertura={JSON.stringify(city.Cobertura_melonn)}
                key={`${city.Codigo_Municipio}-${city.Municipio.replace(
                  "/s/g",
                  "-"
                )}`}
                value={city.Codigo_Municipio}>
                {city.Municipio}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-column'>
          <label htmlFor='direccion'>Dirección</label>
          <input
            type='text'
            id='direccion'
            name='Direccion'
            placeholder='Eje. Calle 123 #45-67'
            value={data.Direccion}
            onChange={onChange}
            required
          />
        </div>
        <div className='form-column'>
          <label htmlFor='complemento'>Complemento de la Dirección</label>
          <input
            type='text'
            id='complemento'
            name='Complemento_Direccion'
            placeholder='Eje. Al frente de un parque'
            value={data.Complemento_Direccion}
            onChange={onChange}
            required
          />
        </div>
      </div>

      <div className='form-row'>
        {enableShipmentType && (
          <div className='form-column'>
            <span className='label'>Tipo de Envío</span>
            <div className='form-column-aux'>
              <label
                className={`radio ${
                  data.Tipo_Envio === "standard" ? "selected" : ""
                }`}>
                <input
                  type='radio'
                  name='Tipo_Envio'
                  value='standard'
                  checked={data.Tipo_Envio === "standard"}
                  onChange={onChange}
                  required
                />
                <span className='icon'>
                  <i className='fa-solid fa-box'></i>
                </span>
                Estandar
              </label>
            </div>
            <div className='form-column-aux'>
              <label
                className={`radio ${
                  data.Tipo_Envio === "currentDay" ? "selected" : ""
                } ${
                  data.Municipio?.Cobertura_melonn !== "Local" ? "disabled" : ""
                }`}>
                <input
                  type='radio'
                  name='Tipo_Envio'
                  value='currentDay'
                  checked={data.Tipo_Envio === "currentDay"}
                  disabled={data.Municipio?.Cobertura_melonn !== "Local"}
                  onChange={onChange}
                  required
                />
                <span className='icon'>
                  <i className='fa-solid fa-truck'></i>
                </span>
                Express: Recibe Hoy
              </label>
            </div>
            <div className='form-column-aux'>
              <label
                className={`radio ${
                  data.Tipo_Envio === "nextDay" ? "selected" : ""
                } ${
                  data.Municipio?.Cobertura_melonn !== "Local" ? "disabled" : ""
                }`}>
                <input
                  type='radio'
                  name='Tipo_Envio'
                  value='nextDay'
                  checked={data.Tipo_Envio === "nextDay"}
                  disabled={data.Municipio?.Cobertura_melonn !== "Local"}
                  onChange={onChange}
                  required
                />
                <span className='icon'>
                  <i className='fa-solid fa-calendar-days'></i>
                </span>
                Express: Recibe Mañana
              </label>
            </div>
          </div>
        )}

        {enablePayment && (
          <div className='form-column'>
            <span className='label'>Forma de Pago</span>
            <div className='form-column-aux'>
              <label
                className={`radio ${
                  data.Forma_Pago === "digital" ? "selected" : ""
                }`}>
                <input
                  type='radio'
                  name='Forma_Pago'
                  value='digital'
                  checked={data.Forma_Pago === "digital"}
                  onChange={onChange}
                  required
                />
                <span className='icon'>
                  <img
                    src={wompiIcon}
                    alt='Wompi Icon'></img>
                </span>
                Wompi
              </label>
            </div>
            <div className='form-column-aux'>
              <label
                className={`radio ${
                  data.Forma_Pago === "contraentrega" ? "selected" : ""
                } ${
                  total > 1000000 ||
                  data.Municipio?.coberturaMelonnContraentrega === "false" ||
                  data.Municipio?.coberturaMelonnContraentrega === ""
                    ? 
                      "disabled"
                    : 
                      ""
                }`}>
                <input
                  type='radio'
                  name='Forma_Pago'
                  value='contraentrega'
                  checked={data.Forma_Pago === "contraentrega"}
                  disabled={
                    total > 1000000 ||
                    data.Municipio?.coberturaMelonnContraentrega === "false" ||
                    data.Municipio?.coberturaMelonnContraentrega === ""
                  }
                  onChange={onChange}
                  required
                />
                <span className='icon'>
                  <i className='fa-solid fa-box'></i>
                </span>
                Contra Entrega
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Shippingform;
