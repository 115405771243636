import { useState, useEffect } from "react";
import { toubelleApi } from "../utils/fetchs";

/**
 * Hook personalizado para obtener datos de una API.
 * @param {string} tipo - El tipo de datos a obtener (por ejemplo, 'documentTypes', 'thirdPartyRoles').
 * @returns {{ data: any, error: any, loading: boolean }} Los datos, el error y el estado de carga.
 * @example
 * const { data, loading, error } = useApi("documentTypes");
 * console.log(data);
 * // => [{ id: 1, codigo: "CC", nombre: "Cédula de ciudadanía" }, { id: 2, codigo: "CE", nombre: "Cédula de extranjería" }]
 */
export const useApi = (type, defaultData = null) => {
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!type) {
      return;
    };

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await toubelleApi.get(`/data/${type}`);
        setData(response?.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      };
    };

    fetchData();
  }, [type]);

  return { data, isLoading, error };
};

/**
 * Hook personalizado para obtener tipos de documentos.
 * @returns {{ data: { id: number, codigo: string, nombre: string }[], error: any, loading: boolean }} Los datos, el error y el estado de carga.
 * @example
 * const { data, loading, error } = useDocumentTypes();
 * console.log(data);
 * // => [{ id: 1, codigo: "CC", nombre: "Cédula de ciudadanía" }, { id: 2, codigo: "CE", nombre: "Cédula de extranjería" }]
 */
export const useDocumentTypes = () => useApi("documentTypes", []);

/**
 * Hook personalizado para obtener roles de terceros.
 * @returns {{ data: { id: number, codigo: string, nombre: string }[], error: any, loading: boolean }} Los datos, el error y el estado de carga.
 * @example
 * const { data, loading, error } = useThirdPartyRoles();
 * console.log(data);
 * // => [{ id: 1, codigo: "AC", nombre: "Acreedor" }, { id: 2, codigo: "DE", nombre: "Deudor" }]
 */
export const useThirdPartyRoles = () => useApi("thirdPartyRoles", []);

/**
 * Hook personalizado para obtener tipos de contribuyentes.
 * @returns {{ data: { id: number, codigo: string, nombre: string }[], error: any, loading: boolean }} Los datos, el error y el estado de carga.
 * @example
 * const { data, loading, error } = useContributorTypes();
 * console.log(data);
 * // => [{ id: 1, codigo: "PN", nombre: "Persona natural" }, { id: 2, codigo: "PJ", nombre: "Persona jurídica" }]
 */
export const useContributorTypes = () => useApi("contributorTypes", []);
