import React, { useContext, useEffect, useState, useMemo } from "react";
import { getProductsCollections } from "../../utils/index.js";
import { CartContext } from "../../contexts/ShoppingCartContext";
import "./ProductPromotions.css";

export const ProductPromotions = () => {
  const [ loading, setLoading ] = useState(true);
  const [categories, setCategories] = useState([]);
  const { productList, setFilterProduct, setFilterTag } =
    useContext(CartContext);

  /**
   * Filtra los productos por categoría.
   * @param {string} category - La categoría por la cual se desea filtrar. Si no se proporciona una categoría, se restablecerá el filtro.
   * @returns {void}
   * @example
   * filterByCategory("Aqua Fresh");
   * console.log(filterTag);
   * // "Aqua Fresh"
   */
  const filterByCategory = (category) => {
    if (category) {
      setFilterTag(category);
    } else {
      setFilterTag("");
      setFilterProduct(productList);
    }
  };

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await getProductsCollections();
        const collections = response.map((collection) => ({
          id: collection.ID,
          name: collection.nameCollection,
          image: collection.urlImageCollection.url,
          description: collection.collectionDescription,
        }));
        console.log(collections);
        setCategories(collections);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCollections();
  }, []);

  const memoizedCategories = useMemo(() => categories, [categories]);

  /**
   * Renderiza los productos promocionales en diferentes categorías. Cada categoría contiene una imagen y una descripción de la fragancia.
   * @returns {JSX.Element} Un elemento JSX que representa el contenido de las promociones de productos.
   * @example
   * renderProducts();
   * // <div className="promotion-content">...</div>
   */
  const renderProducts = () => {
    return (
     <>
      {loading ? 
      <div className="loader-products"><h2 id="subtitle">Cargando Coleciones...</h2></div>
      :
      <div className='promotion-content'>
        <div className='bottle-categories'>
          {memoizedCategories.map((category, index) => (
            <div
              className='col-category'
              key={index}>
              <div
                className='bottle-category'
                onClick={() => filterByCategory(category)}>
                <div className='image-container'>
                  <img
                    loading='lazy'
                    src={category.image}
                    alt='termo'
                  />
                </div>
                <div className='body-container'>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      filterByCategory(category);
                    }}>
                    Colección {category.name}
                  </span>
                  <p>{category.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>  
      }
     </>
    );
  };

  return (
    <section className='promotion-section'>
      <div className='promotion-header'>
        <div className='promotion-title'>
          <h2>Conoce las colecciones TouBelle</h2>
        </div>
      </div>
      {renderProducts()}
    </section>
  );
};
