import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";
import imageNotFound from "../../assets/images/branding/image-not-found.png";

export const Error = () => {
  return (
    <>
      <div className="container-notfound">
        <div className="container">
          <div className="image-container">
            <img src={imageNotFound} alt="Imagen que hace referencia a no encontrado."></img>
          </div>
          <div className="text-container">
            <h1>Página no encontrada</h1>
            <span className="text">
              Lo sentimos, No hemos podido encontrar la página que buscas. Verifica la URL o puedes <Link to="/">regresar a la página de inicio</Link>. ¡Estamos aquí para ayudarte!
            </span>
          </div>
        </div>
      </div>
    </>
  );
};