import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { moneyFormat, displayShippingValue } from "../../utils/index.js";
import "../order-summary/OrderSummary.css";

export const OrderSummary = ({ formId, isSubmitting, message }) => {
  const {
    total,
    setShipmentData,
    shipping,
    setDataRecord,
    setDiscount,
    setDocument,
    deliveryData,
  } = useContext(CartContext);


  /**
   * Función para desactivar las variables que controlan el componente
   * @function setShipmentData - Función para ocultar la vista cuando el usuario está registrado
   * @function setDataRecord - Función para ocultar la vista cuando el usuario no está registrado
   * @function setDiscount - Función para eliminar los datos del cupón
   * @function setInputValue - Función para limpiar el campo del cupón
   * @function setDocument - Función para limpiar la variable documento
   * @returns {void}
   * @example
   * deactivate();
   * console.log(shipmentData);
   * // ""
   */
  const deactivate = () => {
    setShipmentData("");
    setDataRecord(false);
    setDiscount([]);
    setDocument("");
  };

  return (
    <>
      <div className="cart-content-summary">
        <div className="cart-summation">
          <div className="body-summation">
            <div className="row">
              <div className="mycart-item-text">Subtotal:</div>
              <div className="mycart-item-price">{moneyFormat(total)}</div>
            </div>
            <div className="row">
              <div className="mycart-item-text">Costo de Envío:</div>
              <div className="mycart-item-price">
                {displayShippingValue(shipping)}
              </div>
            </div>
            <div className="footer-summation">
              <div className="grand-total">Total:</div>
              <div className="total-mycart">
                {moneyFormat(total + Math.max(shipping, 0))}
              </div>
            </div>
          </div>
        </div>
        <div className="cart-message">
          <p>{message}</p>
        </div>
        <div className="cart-options">
          <button
            className="button-pay"
            id="btnPedir"
            form={formId}
            type="submit"
            disabled={isSubmitting}>
            {isSubmitting ? (
              deliveryData.Forma_Pago !== "contraentrega" ?
                  <>
                    Procesando Pago <i className="fa-solid fa-spinner fa-spin"></i>
                  </>
                : 
                  <>
                    Generando Pedido <i className="fa-solid fa-spinner fa-spin"></i>
                  </> 
            ) : (
            "Realizar Pago"
            )}
          </button>
          <Link
            to="/tienda"
            className="button-buy"
            onClick={deactivate}
            disabled={isSubmitting}
          >
            Seguir Comprando
          </Link>
        </div>
      </div>
    </>
  );
};
