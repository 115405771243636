import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { moneyFormat } from "../../utils";
import { CartContext } from "../../contexts/ShoppingCartContext";
import "../order-details/OrderDetails.css";

export const OrderDetails = () => {
  const [inputValues, ] = useState({});
  const {
    allProducts,
    setAllProducts,
    total,
    setTotal,
    setCountProducts,
    countProducts,
    setDiscount
  } = useContext(CartContext);

  /**
   * Maneja el evento de cambio de entrada para un producto específico en los detalles del pedido.
   * @param {Evento} event - El evento de cambio de entrada.
   * @param {string} productId - El ID del producto.
   * @returns {void}
   * @example
   * handleInputChange(event, productId);
   * console.log(allProducts);
   * // [{ id: 1, name: "Producto 1", quantity: 2, price: 10000 }, { id: 2, name: "Producto 2", quantity: 1, price: 20000 }]
   */
  const handleInputChange = (event, productId) => {
    let { value } = event.target;
    console.log(value);
    if (value === "" || value < 0) {
      console.log(value);
    };
    const  updatedProducts = allProducts.map((product) =>
      product.id === productId ? { ...product, quantity: value } : product
    );
    setAllProducts(updatedProducts);
  };

  /**
   * Maneja el evento de desenfoque de un elemento de entrada.   * 
   * @param {Evento} event - El objeto de evento de desenfoque.
   * @param {string} productId - El ID del producto.
   * @returns {void}
   * @example
   * onInputBlur(event, productId);
   * console.log(allProducts);
   * // [{ id: 1, name: "Producto 1", quantity: 2, price: 10000 }, { id: 2, name: "Producto 2", quantity: 1, price: 20000 }]
   */
  const onInputBlur = (event, productId) => {
    let value = event.target.value;
    value = value.replace(/[^\d]/g, "");
    if (value === "" || value === "0") {
      value = 1;
    } else {
      value = parseInt(value);
    };
    const updatedProducts = allProducts.map((product) =>
      product.id === productId ? { ...product, quantity: value } : product
    );
    setAllProducts(updatedProducts);
  };

  /**
   * Actualiza la cantidad de un producto y realiza los cálculos necesarios.   * 
   * @param {Object} product - El producto que se va a actualizar.
   * @param {string} action - La acción que se va a realizar en el producto. Puede ser "add" o "subtract".
   * @returns {void}
   * @example
   * onUpdateProduct(product, action);
   * console.log(allProducts);
   * // [{ id: 1, name: "Producto 1", quantity: 2, price: 10000 }, { id: 2, name: "Producto 2", quantity: 1, price: 20000 }]
   */
  const onUpdateProduct = (product, action) => {
    const updatedProducts = allProducts.map((item) => {
      if(item.id === product.id) {
        const updatedQuantity = action === "add" ? item.quantity + 1 : item.quantity - 1;
        return { ...item, quantity: updatedQuantity };
      };
      return item;
    });

    const updatedProduct = updatedProducts.find((item) => item.id === product.id);
    if(updatedProduct.quantity === 0) {
      onDeleteProduct(updatedProduct);
    } else {
      const priceChange = action === "add" ? parseInt(product.Precio) : -parseInt(product.Precio);
      setTotal(total + priceChange);
      setCountProducts(getTotalQuantity());
      setAllProducts(updatedProducts);
    };
  };

  /**
   * Elimina un producto de la lista de todos los productos.
   * @param {Object} product - El producto que se va a eliminar.
   * @returns {void}
   * @example
   * onDeleteProduct(product);
   * console.log(allProducts);
   * // []
   */
  const onDeleteProduct = (product) => {
    const updatedProducts = allProducts.filter(
      (item) => item.id !== product.id
    );
    setTotal(total - product.quantity * parseInt(product.price));
    setAllProducts(updatedProducts);
    if ((total - product.quantity * parseInt(product.price)) === 0) {
      setDiscount([]);
    };
  };

  /**
   * Calcula el total de la cantidad de productos.
   * @returns {number} La cantidad total de productos.
   * @example
   * const totalQuantity = getTotalQuantity();
   * console.log(totalQuantity);
   * // 5
   */
  const getTotalQuantity = () => {
    return allProducts.reduce((acc, product) => acc + product.quantity, 0);
  };

  /**
   * Calcula el costo total de todos los productos en el pedido.
   * @returns {number} El costo total del pedido.
   * @example
   * const total = calculateTotal();
   * console.log(total);
   * // 50000
   */
  const calculateTotal = () => {
    return allProducts.reduce(
      (acc, product) => acc + product.quantity * parseInt(product.price), 0);
  };

  useEffect(() => {
    setCountProducts(getTotalQuantity());
    setTotal(calculateTotal());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts]);


  /**
   * Vacía el carrito de compras.
   * Esta función restablece el estado del carrito de compras a su estado inicial.
   * - Limpia la lista de productos.
   * - Establece el total a 0.
   * - Establece el conteo de productos a 0.
   * - Limpia los descuentos aplicados.
   */
  const emptyCart = () => {
    setAllProducts([]);
    setTotal(0);
    setCountProducts(0);
    setDiscount([]);
  };

  return (
    <div className="cart-content-details">
      {allProducts.length ? (
        <div>
          <div className="cart-content-details-header">
            <h2>Tus Productos</h2>
            {countProducts !== 0 ? (
              <Link className="" onClick={() => emptyCart()}>
                Vaciar carrito
              </Link>
            ) : undefined}
          </div>
          <div className="cart-list">
            {allProducts.map((product) => (
              <div className="cart-item" key={product.id}>
                <div className="product-image">
                  <img src={product.image} alt="Etiqueta de Producto." />
                </div>
                <div className="product-info">
                  <span className="category">{product.category}</span>
                  <span className="name">{product.name}</span>
                </div>
                <div className="product-price">
                  <span className="price">
                    {moneyFormat(product.price)} COP
                  </span>
                </div>
                <div className="product-amount">
                  <button
                    className="change-quantity"
                    onClick={() => onUpdateProduct(product, "remove")}
                  >
                    <i className="fa-solid fa-minus"></i>
                  </button>
                  <input
                    type="number"
                    id="cantidad"
                    name="cantidad"
                    max={product.amount}
                    value={
                      inputValues[product.id] !== undefined
                        ? inputValues[product.id] === 1
                          ? ""
                          : inputValues[product.id]
                        : product.quantity
                    }
                    onChange={(event) => {
                      const value = Math.min(event.target.value, product.amount);
                      handleInputChange({ ...event, target: { ...event.target, value } }, product.id);
                    }}
                    onBlur={(event) => onInputBlur(event, product.id)}
                  ></input>
                  <button
                    className="change-quantity"
                    disabled={product.quantity >=  product.amount}
                    onClick={() => onUpdateProduct(product, "add")}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
                <div className="product-option">
                  <button
                    className="delete"
                    onClick={() => onDeleteProduct(product)}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h2 className="cart-empty">El carrito está vacío</h2>
      )}
    </div>
  );
};
