import { capitalizeWords } from "./index";

/**
 * Maneja el cambio de valores en un formulario de React.
 * @param {Object} values - Los nuevos valores que se van a establecer.
 * @param {Function} setValues - La función para actualizar el estado de los valores.
 * @param {Function} [callback] - Función opcional que se ejecuta después de actualizar los valores.
 * @default (values) => {}
 * @example
 * const [values, setValues] = useState({ name: "", email: "" });
 * const handleChange = handleChange(values, setValues);
 * console.log(values);
 * // { name: "", email: "" }
 */
export const handleChange = (values, setValues, callback = () => {}) => {
  return (event) => {
    const { name, value, checked, type, dataset, selectedOptions } =
      event.target;

    let parsedOptions = {};

    if (selectedOptions) {
      parsedOptions = parseDataset(selectedOptions[0].dataset);
    };

    const newValues = {
      ...values,
      ...parseDataset(dataset),
      ...parsedOptions,
      [name]: type === "checkbox" ? checked : value,
    };
    
    setValues(newValues);
    callback(newValues);
  };
};

/**
 * Parsea un objeto de dataset transformando sus claves y valores.
 * @param {Object} dataset - El objeto que contiene los datos a parsear.
 * @returns {Object} Un nuevo objeto con las claves capitalizadas y valores parseados.
 * @example
 * const dataset = { first_name: "John", last_name: "Doe" };
 * const parsed = parseDataset(dataset);
 * console.log(parsed);
 * // { FirstName: "John", LastName: "Doe" }
 */
export const parseDataset = (dataset) => {
  const parsed = {};

  Object.keys(dataset).forEach((key) => {
    parsed[capitalizeWords(key)] = JSON.parse(dataset[key]);
  });

  return parsed;
};
