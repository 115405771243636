import React, { createContext, useEffect, useState } from 'react'

export const CartContext = createContext();

/**
 * Proveedor de contexto para el carrito de compras. Este componente proporciona el contexto necesario para manejar el estado del carrito de compras,
 * incluyendo productos, total, conteo de productos, datos de envío, descuentos, y más.
 * @component
 * @param {Object} props - Las propiedades del componente.
 * @param {React.ReactNode} props.children - Los componentes hijos que serán envueltos por el proveedor.
 * @returns {JSX.Element} Un proveedor de contexto que envuelve los componentes hijos.
 * @example
 * return (
 *  <ShoppingCartProvider>
 *   <App />
 * </ShoppingCartProvider>
 * );
 */
export const ShoppingCartProvider = ({ children }) => {
  const [productList, setProductList] = useState([]);
  const [shipmentData, setShipmentData] = useState(''); 
  const [dataRecord, setDataRecord] = useState(false); 
  const [document, setDocument]= useState('');
  const [filterProduct, setFilterProduct]= useState([]); 
  const [search, setSearch]= useState([]);
  const [discount, setDiscount]= useState([]); 
  const [filterTag, setFilterTag] = useState(''); 
  const [companyInfo, setCompanyInfo] = useState({});
  const [shipping, setShipping] = useState(-2);
  const [deliveryData, setDeliveryData] = useState({});
  const [thirdPersonData, setThirdPersonData] = useState({});

  /**
   * Estado que contiene todos los productos. Intenta recuperar los datos de localStorage, o retorna un array vacío si no hay datos.
   * @constant
   * @type {Array}
   * @returns {Array} - Un array con todos los productos.
   * @function setAllProducts - Función para actualizar la lista
   * @example
   * setAllProducts([]);
   * console.log(allProducts);
   * // []
   */
  const [allProducts, setAllProducts] = useState(() => {
    const storagedProducts = localStorage.getItem('allProducts');
    return storagedProducts ? JSON.parse(storagedProducts) : [];
  });

  /**
   * Estado que almacena el total del carrito de compras. Intenta recuperar el total desde localStorage, o retorna 0 si no hay datos.
   * @constant {number} total - El total del carrito de compras.
   * @function setTotal - Función para actualizar el total del carrito de compras.
   * @returns {number} - El total recuperado de localStorage o 0 si no hay datos.
   * @example
   * setTotal(10000);
   * console.log(total);
   * // 10000
   */
  const [total, setTotal] = useState(() => {
    const storedTotal = localStorage.getItem('total');
    return storedTotal ? parseInt(storedTotal) : 0;
  });

  /**
   * Estado que mantiene el conteo de productos en el carrito de compras. Intenta recuperar el conteo de productos desde localStorage, o retorna 0 si no hay datos.
   * @constant
   * @type {[number, Function]}
   * @returns {number} - El conteo de productos en el carrito de compras.
   * @function setCountProducts - Función para actualizar el conteo de productos.
   * @example
   * setCountProducts(5);
   * console.log(countProducts);
   * // 5
   */
  const [countProducts, setCountProducts] = useState(() => {
    const storedCountProducts = localStorage.getItem('countProducts');
    return storedCountProducts ? parseInt(storedCountProducts) : 0;
  });

  useEffect(() => {
    localStorage.setItem('allProducts', JSON.stringify(allProducts));
  }, [allProducts]);

  useEffect(() => {
    localStorage.setItem('total', total.toString());
  }, [total]);

  useEffect(() => {
    localStorage.setItem('countProducts', countProducts.toString());
  }, [countProducts]);
  
  return (
    <CartContext.Provider value={{
      allProducts,
      setAllProducts,
      total,
      setTotal,
      countProducts,
      setCountProducts,
      shipmentData,
      setShipmentData,
      shipping,
      setShipping,
      deliveryData,
      setDeliveryData,
      thirdPersonData,
      setThirdPersonData,
      dataRecord,
      setDataRecord,
      productList,
      setProductList,
      document,
      setDocument,
      filterProduct,
      setFilterProduct,
      search,
      setSearch, 
      discount,
      setDiscount,
      filterTag,
      setFilterTag,
      companyInfo,
      setCompanyInfo
      }}>
      {children}
    </CartContext.Provider>
  );
};
