import React, { useEffect } from "react";
import LogoToubelle from "../../../assets/images/branding/logo-toubelle.png";
import "./Loading.css";

export const Loading = () => {
  useEffect(() => {
    const loader = document.querySelector(".loader");
    const lineWrap = '<div class="loader-line-wrap"><div class="loader-line"></div></div>';
    const img = '<div class="loader-inner">' + lineWrap + "</div>";
    loader.insertAdjacentHTML("beforeend", img);
  }, []); 

  return (
    <div className="loader">
      <img src={LogoToubelle} alt="toubelle" />
    </div>
  );
};
