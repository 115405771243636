import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";  
import { getWompiTransaction, getMelonOrder } from "../../utils/fetchs";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { BannerList } from '../../components/banner-list/BannerList';
import { ProductPromotions } from '../../components/product-promotions/ProductPromotions';
import { ProductList } from '../../components/product-list/ProductList';
import { Loading } from "../../components/layouts/loading/Loading";
import { Error } from "../error/Error";
import "./Home.css";

export const Home = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const { setProductList, setAllProducts, setTotal, setCountProducts } = useContext(CartContext);
  const [ , setInputValues ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const [ loadingProduct, setLoadingProduct ] = useState(true);
  const [ error, setError ] = useState(undefined);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Vacía el carrito de compras. Esta función utiliza `useCallback` para memorizar la función y evitar recrearla en cada renderizado. Al ejecutarse, vacía la lista de productos, 
   * establece el total a 0 y el contador de productos a 0.
   * @function
   * @name emptyCart
   * @returns {void}
   * @example
   * emptyCart();
   * console.log(allProducts);
   * // []
   */
  const emptyCart = useCallback(() => {
    setAllProducts([]);
    setTotal(0);
    setCountProducts(0);
  }, [setAllProducts, setTotal, setCountProducts]);

  useEffect(() => {
    if (!location.search) return;

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    /**
     * Obtiene el estado de una transacción de pago y vacía el carrito si el pago es aprobado.
     * @async
     * @function fetchPaymentStatus
     * @param {string} id - El ID de la transacción de pago.
     * @returns {Promise<void>} - Una promesa que se resuelve cuando se obtiene el estado del pago.
     * @example
     * fetchPaymentStatus("123456");
     * console.log("Pago aprobado");
     * // "Pago aprobado"
     */
    const fetchPaymentStatus = async (id) => {
      try {
        const { data } = await getWompiTransaction(id);
        if (data?.status === 'APPROVED') {
          emptyCart();
        };
      } catch (error) {
        console.error('Error al obtener el estado del pago:', error);
      };
    };

    fetchPaymentStatus(id);
  }, [emptyCart, location.search]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getMelonOrder();
        if (!data) {
          throw new Error('No se recibieron productos');
        };
  
        /**
         * Modifica una lista de productos agregando propiedades adicionales.
         * @param {Array} data - Lista de productos a modificar.
         * @returns {Array} Lista de productos modificados con las siguientes propiedades adicionales:
         * - category: Categoría del producto, o 'Sin categoría' si no está disponible.
         * - quantity: Cantidad inicial del producto, establecida en 0.
         * - valueTotalProduct: Valor total inicial del producto, establecido en 0.
         * - IdProduct: Identificador del producto.
         * @example
         * const products = [
         *  { ID: 1, categoriesWebCards: { nameCategories: "Cuidado de la piel" } },
         *  { ID: 2, categoriesWebCards: { nameCategories: "Maquillaje" } }
         * ];
         * const modifiedProducts = modifyProducts(products);
         * console.log(modifiedProducts);
         * // [ { ID: 1, category: "Cuidado de la piel", quantity: 0, valueTotalProduct: 0, IdProduct: 1 },
         *    { ID: 2, category: "Maquillaje", quantity: 0, valueTotalProduct: 0, IdProduct: 2 } ]
         */
        const modifiedProducts = data.map(product => {
          const category = product['categoriesWebCards.nameCategories'] || 'Sin categoría';
          return {
            ...product,
            category,
            quantity: 0,
            valueTotalProduct: 0,
            IdProduct: product.ID
          };
        });
  
        /**
         * Mapea una lista de productos modificados a un nuevo formato con propiedades específicas.
         * @param {Array} modifiedProducts - Lista de productos modificados.
         * @returns {Array} Lista de productos con las propiedades mapeadas.
         * @property {number} id - Identificador del producto.
         * @property {number} idProduct - Identificador del producto.
         * @property {string} characteristics - Descripción de las características del producto.
         * @property {string} name - Nombre del producto.
         * @property {number} price - Precio del producto.
         * @property {string} image - URL de la imagen del producto.
         * @property {string} category - Categoría del producto.
         * @property {number} quantity - Cantidad disponible del producto.
         * @property {string} sku - Código SKU del producto.
         * @property {number} valueTotalProduct - Valor total del producto.
         * @property {string} promotion - Indica si el producto tiene descuento.
         * @property {number} amount - Cantidad disponible del producto.
         * @property {string} compositeProduct - Indica si el producto es compuesto.
         * @property {string} productPlan - Plano del producto.
         */
        const productProperties = modifiedProducts.map(product => ({
          id: product.ID,
          idProduct: product.IdProduct,
          characteristics: product.productWebCards?.descriptionLintegrateProducts || '',
          name: product.productNameWebCards || '',
          price: product.priceWebCard || 0,
          image: product.publicImageWebCard?.url || '',
          category: product.category || '',
          categoryId: product['collectionProductsWebCards.ID'] || '',
          quantity: product.inventoryData?.availableQuantity || 0,
          codeLintegrateProducts: product['productWebCards.codeLintegrateProducts'] || '',
          sku: product.productWebCards.productCodeLintegrateProducts || '',
          valueTotalProduct: product.valueTotalProduct || 0,
          promotion: product.hasDiscountWebCard || 'No',
          amount: product.inventoryData?.availableQuantity || 0,
          compositeProduct: product.Producto_Compuesto || '',
          productPlan: product.Plano_de_Producto || ''
        }));
  
        setProductList(productProperties);
        console.log(productProperties);
  
        /**
         * Generates an object with initial values set to 1 for each item in the data array.
         * @param {Array} data - The array of data objects.
         * @param {Object} data[].id - The unique identifier for each data object.
         * @returns {Object} An object where each key is the id from the data array and the value is set to 1.
         * @example
         * const data = [{ id: 1 }, { id: 2 }, { id: 3 }];
         * const initialValues = generateInitialValues(data);
         * console.log(initialValues);
         * // { 1: 1, 2: 1, 3: 1 }
         */
        const initialValues = data.reduce((acc, curr) => {
          acc[curr.id] = 1;
          return acc;
        }, {});
  
        setInputValues(initialValues);
      } catch (error) {
        setError("Error al obtener los productos");
        console.error("Error al obtener los productos:", error);
      } finally {
        setLoadingProduct(false);
      };
    };

    fetchProducts()
  }, [setProductList]);
  
  useEffect(() => {
    if (error) {
      navigate("/error");  
    }
  }, [error, navigate]);

  return (
    <>
      {loading && <Loading />}
      <BannerList />
      <ProductPromotions />
      {!loading && (
        <>          
          {loadingProduct ? (
            <div className="loader-products"><h2>Cargando los productos TouBelle...</h2></div>
          ) : (
            <ProductList />
          )}
        </>
      )}
    </>
  );
};
