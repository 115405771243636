import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { moneyFormat, getThirdPerson } from "../../utils";
import "../user-validation/UserValidation.css";


export const UserValidation = () => {
  const {
    total,
    countProducts,
    setShipmentData,
    setDataRecord,
    setDocument,
  } = useContext(CartContext);

  const [, setInputValue] = useState("");

  /**
   * Función para desactivar las variables que controlan el componente
   * @function setShipmentData - Función para ocultar la vista cuando el usuario está registrado
   * @function setDataRecord - Función para ocultar la vista cuando el usuario no está registrado
   * @function setDiscount - Función para eliminar los datos del cupón
   * @function setInputValue - Función para limpiar el campo del cupón
   * @function setDocument - Función para limpiar la variable documento
   * @returns {void}
   * @example
   * deactivate();
   * console.log(shipmentData);
   * // ""
   */
  const deactivate = () => {
    setShipmentData('');
    setDataRecord(false);
    setInputValue("");
    setDocument("");
  };
  
  /**
   * Valida un documento y recupera datos basados en la longitud del documento.
   * @async
   * @function validationDocument
   * @returns {Promise<void>} - Una promesa que se resuelve una vez que se completa la validación.
   * @throws {Error} - Error al intentar validar el documento.
   * @example
   * validationDocument(event);
   * console.log("Datos del usuario:", dataObject);
   * // "Datos del usuario: { user: { name: 'John Doe', document: '1234567890' } }"
   */
  const validationDocument = async (e) => {
    e.preventDefault();
    
    try {
      const documentValue = e.target.documento.value;
      setDocument(documentValue);

      const data = await getThirdPerson(documentValue);
      console.log("tercero", data)
      if(data.message) {
        setDataRecord(true);
        return;
      };

      const dataObject = data.reduce((obj, item) => {
        obj['user'] = item;
        return obj;
      }, {});

      setDataRecord(false);
      setShipmentData(dataObject);
      console.log('Datos del usuario:', dataObject);
      
    } catch(error) {
      setShipmentData("");
      console.error('Error en la validación:', error);
    };
  };

  return (
    <form onSubmit={validationDocument}>
      <div className='cart-content-summary'>
        {countProducts !== 0 ? (
          <div>
            <div className='validation-user'>
              <label htmlFor='documento'>Ingresa tu número de cédula aquí:</label>
              <input
                type='tel'
                id='documento'
                name='documento'
                maxLength={11}
                minLength={3}
                required
                placeholder='Ingresa su número de documento.'>
              </input>
            </div>
          </div>
        ) : undefined}
        <div className='cart-summation'>
          <div className='body-summation'>
            <div className='row'>
              <div className='mycart-item-text'>Subtotal:</div>
              <div className='mycart-item-price'>
                {moneyFormat(total)}
              </div>
            </div>
          </div>
        </div>
        <div className='cart-options'>
          {countProducts !== 0 ? (
            <button
              className='button-pay'
              type="submit">
              Continuar Compra
            </button>
          ) : undefined}
          <Link
            to='/tienda'
            className='button-buy'
            onClick={deactivate}>
            Seguir Comprando
          </Link>
        </div>
      </div>
    </form>
  );
};
