import axios from "axios";

/**
 * Instancia de Axios configurada para interactuar con la API de Toubelle.
 *
 * @constant {AxiosInstance} toubelleApi - Instancia de Axios.
 * @example
 * const response = await toubelleApi.get('/endpoint');
 */
const toubelleApi = axios.create({
  baseURL: [process.env.REACT_APP_URL_TOUBELLE_API, "api"].join("/"),
  timeout: 30000,
  headers: {
    "Content-Type": "application/json"
  },
});

/**
 * Instancia de Axios para realizar solicitudes a la API de Asinfy.
 * @constant
 * @type {AxiosInstance}
 * @example
 * const response = await asinfyApi.get('/endpoint');
 */
const asinfyApi = axios.create({
  baseURL: process.env.REACT_APP_ASINFY_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Creates an Axios instance configured for Wompi API transactions.
 * 
 * @constant {AxiosInstance} wompiApi - The Axios instance for Wompi API.
 * @property {string} baseURL - The base URL for Wompi API transactions, sourced from environment variables.
 * @property {string} headers.Content-Type - The content type set to "application/json".
 * @property {string} headers.Authorization - The authorization header containing the Bearer token sourced from environment variables.
 */
const wompiApi = axios.create({
  baseURL: process.env.REACT_APP_WOMPI_URL_TRANSACTONS,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_KEY}`,
  },
});

/**
 * Obtiene la información de una tercera persona mediante su identificación en la API de World Office.
 * @param {string} id - La identificación de la tercera persona.
 * @returns {Promise<any>} - Una promesa que se resuelve con los datos de la tercera persona.
 * @example
 * const thirdPersonData = await getThirdPerson("ID_TERCERO");
 */
export const getThirdPerson = async (id) => {
  const response = await toubelleApi.get(`/users/getThirdParty/${id}`);
  console.log(response);
  return response?.data;
};

/**
 * Función asincrónica que envía una solicitud de preorden a través de una llamada a la API de Zoho.
 * @param {Object} data - Los datos de la preorden a enviar.
 * @returns {Promise<Object>} - Una promesa que se resuelve con los datos de respuesta de la API de Zoho.
 * @example
 * const response = await createPreOrder({ clave1: "valor1" });
 */
export const createPreOrder = async (data) => {
  const response = await toubelleApi.post("/orders/createPreorder", data);
  return response?.data;
};

/**
 * Obtiene el los productos de la api melon.
 * @async
 * @param {string} product - endopoint producto.
 * @returns {Promise<any>} - Una promesa que se resuelve con los datos de los productos.
 * @example
 * const melonProducts = await getMelonOrder();
 */
export const getMelonOrder = async () => {
  const response = await toubelleApi.get("/products/compare");
  return response?.data;
};

/**
 * Obtiene las colecciones de productos desde la API.
 * @async
 * @param {string} product - endopoint producto.
 * @returns {Promise<Object>} Una promesa que resuelve con los datos de las colecciones de productos.
 * @example
 * const productCollections = await getProductsCollections();
 */
export const getProductsCollections = async () => {
  const response = await toubelleApi.get("/products/collections");
  return response?.data;
}

/**
 * Obtiene los datos de los municipios.
 * @async
 * @returns {Promise} Una promesa que se resuelve con los datos de los municipios.
 * @example
 * const datosMunicipios = await getMunicipalities();
 */
export const getMunicipalities = async () => {
  const response = await toubelleApi.get("/departments");
  return response?.data;
};

/**
 * Obtiene los datos de los municipios según el código de departamento.
 * @param { string } departmentCode - El código de departamento.
 * @returns {Promise<any>} - Una promesa que se resuelve con los datos de los municipios.
 * @example
 * const datosMunicipios = await getMunicipalitiesCode("CODIGO_DEPARTAMENTO");
 */
export const getMunicipalitiesCode = async (departmentCode) => {
  const response = await toubelleApi.get(`/departments/${departmentCode}/municipalities`);
  return response?.data;
};

/**
 * Realiza una solicitud POST a una URL con los datos proporcionados.
 * @param {object} data - Los datos a enviar.
 * @returns {Promise} - Una promesa que se resuelve con los datos de la respuesta.
 * @example
 * const responseData = await postData("https://ejemplo.com/api/recurso", { clave1: "valor1" });
 */
export const signInWompi = async (data) => {
  const response = await asinfyApi.post("/termox/signature", data);
  return response?.data;
};

/**
 * Obtiene una transacción de Wompi por su ID.
 * @param {string} id - El ID de la transacción a obtener.
 * @returns {Promise<Object>} - Una promesa que resuelve con los datos de la transacción.
 * @example
 * const transactionData = await getWompi("ID_TRANSACCION");
 */
export const getWompiTransaction = async (id) => {
  const response = await wompiApi.get(`/transactions/${id}`);
  return response?.data;
};

export const createOrden = async (data) => {
  const response = await toubelleApi.post("/orders/createOrderCOD", data);
  return response?.data;
};

export { toubelleApi };