import React, { useState, useEffect } from "react";
import "./BannerList.css";
import BannerImg01 from "../../assets/images/branding/banner-toubelle-01.jpg";

export const BannerList = () => {
  const images = [BannerImg01];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <div className="slider-container">
        <div
          className="slider"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: 'transform 0.5s ease',
          }}
        >
          {images.map((image, index) => (
            <div className="slide" key={index}>
              <img src={image} alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </div>

        {images.length > 1 && (
          <div className="dots">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
                aria-label={`Ir a la diapositiva ${index + 1}`}
              ></span>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
