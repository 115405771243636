import React, { useContext } from "react";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { OrderDetails } from '../order-details/OrderDetails';
import { UserValidation } from "../user-validation/UserValidation";
import "./TimeLineCart.css";


export const TimeLineCart = () => {
  const {
    shipmentData,
    makePayment, 
    dataRecord
  } = useContext(CartContext);

  return (
    <>
    {!makePayment && !dataRecord && !shipmentData  ? (
      <div className="shopping-cart-container">
        <h1>Carrito de compras</h1>
        <div className="shopping-cart-content">
          <OrderDetails/>
          <UserValidation/>
        </div>
      </div>
      ) : undefined}
    </>
  );
}
