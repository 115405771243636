import React, { useEffect, useContext, useMemo } from "react";
import { useDocumentTypes, useContributorTypes, useThirdPartyRoles, userOrderData } from "../../hooks";
import Shippingform from "../shipping-form/ShippingForm";
import { CartContext } from "../../contexts";
import { handleChange } from "../../utils";
import "./UserAccount.css";

export const UserAccount = ({ onSubmit, formId, isSubmitting }) => {
  const { shipmentData, setDeliveryData, setThirdPersonData } = useContext(CartContext);
  const { data: contributorTypes } = useContributorTypes();
  const { data: thirdPartyRoles } = useThirdPartyRoles();
  const { data: documentTypes } = useDocumentTypes();
  const [formData, setFormData] = userOrderData();
  const specificThirdPartyRoleCode = 4;
  const specificContributorTypeCode = 6;

  /**
   * Utiliza `useMemo` para seleccionar un documento basado en el tipo de documento y el formulario de datos.
   * @returns {Object} El documento seleccionado que coincide con la abreviatura especificada en `formData.Tipo1`.
   * @example
   * const selectedDocument = useMemo(() => getSelectedDocument(documentTypes, formData.Tipo1), [documentTypes, formData.Tipo1]);
   * console.log(selectedDocument);
   * // { id: 1, nombre: "Cédula de Ciudadanía", abreviatura: "CC" }
   */
  const selectedDocument = useMemo(() => {
    return documentTypes.find((doc) => doc.abreviatura === formData.Tipo1);
  }, [documentTypes, formData.Tipo1]);

  /**
   * Utiliza useMemo para memorizar el rol de terceros seleccionado.
   * @param {Array} thirdPartyRoles - Lista de roles de terceros disponibles.
   * @param {string} specificThirdPartyRoleCode - Código específico del rol de terceros que se desea seleccionar.
   * @returns {Object} El rol de terceros que coincide con el código específico de rol de terceros.
   * @example
   * const selectedThirdPartyRole = useMemo(() => getSelectedThirdPartyRole(thirdPartyRoles, specificThirdPartyRoleCode), [thirdPartyRoles, specificThirdPartyRoleCode]);
   * console.log(selectedThirdPartyRole);
   */
  const selectedThirdPartyRole = useMemo(() => {
    return thirdPartyRoles.find(
      (role) => role.id === specificThirdPartyRoleCode
    );
  }, [thirdPartyRoles, specificThirdPartyRoleCode]);

  /**
   * Utiliza useMemo para seleccionar un tipo de contribuyente específico basado en su código.
   * @constant {Object} selectedContributorType - El tipo de contribuyente seleccionado.
   * @returns {Object} - El tipo de contribuyente que coincide con el código específico.
   * @example
   * const selectedContributorType = useMemo(() => getSelectedContributorType(contributorTypes, specificContributorTypeCode), [contributorTypes, specificContributorTypeCode]);
   * console.log(selectedContributorType);
   * // { id: 1, nombre: "Persona Natural", codigo: 6 }
   */
  const selectedContributorType = useMemo(() => {
    return contributorTypes.find(
      (type) => type.id === specificContributorTypeCode
    );
  }, [contributorTypes, specificContributorTypeCode]);

  useEffect(() => {
    if (selectedContributorType && selectedDocument && selectedThirdPartyRole) {
      setThirdPersonData({
        selectedContributorType,
        selectedDocument,
        selectedThirdPartyRole,
      });
    }
  }, [
    selectedContributorType,
    selectedDocument,
    selectedThirdPartyRole,
    setThirdPersonData,
  ]);

  useEffect(() => {
    setDeliveryData(formData);
  }, [formData, setDeliveryData]);

  const onChange = handleChange(formData, setFormData);

  return (
    <form
      id={formId}
      name='userAccountForm'
      disabled={isSubmitting}
      onSubmit={onSubmit}>
      <div className='user-account-form-container'>
        <h2>Datos de Facturación</h2>
        <div className='form-row'>
          <div className='form-column'>
            <label htmlFor='nombre'>Nombre</label>
            <input
              type='text'
              id='nombre'
              name='Nombre'
              value={formData.Nombre}
              placeholder='Eje. Juan'
              disabled={!!shipmentData}
              onChange={onChange}
              required
            />
          </div>
          <div className='form-column'>
            <label htmlFor='segundo_nombre'>Segundo Nombre</label>
            <input
              type='text'
              id='segundo_nombre'
              name='Segundo_Nombre'
              value={formData.Segundo_Nombre}
              placeholder='Eje. Carlos'
              disabled={!!shipmentData}
              onChange={onChange}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-column'>
            <label htmlFor='primer_apellido'>Apellido</label>
            <input
              type='text'
              id='primer_apellido'
              name='Primer_Apellido'
              value={formData.Primer_Apellido}
              placeholder='Eje. Perez'
              disabled={!!shipmentData}
              onChange={onChange}
              required
            />
          </div>
          <div className='form-column'>
            <label htmlFor='segundo_apellido'>Segundo Apellido</label>
            <input
              type='text'
              id='segundo_apellido'
              name='Segundo_Apellido'
              value={formData.Segundo_Apellido}
              placeholder='Eje. Jimenez'
              disabled={!!shipmentData}
              onChange={onChange}
              required
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-column'>
            <label htmlFor='tipo_documento'>Tipo de Documento</label>
            <select
              id='tipo_documento'
              name='Tipo1'
              value={formData.Tipo1}
              onChange={onChange}
              disabled={Boolean(shipmentData?.user?.Tipo1)}
              required>
              <option
                disabled
                hidden
                value=''>
                Seleccione un tipo de documento
              </option>
              {documentTypes.map((document) => (
                <option
                  data-identificacion={JSON.stringify(document)}
                  value={document.abreviatura}
                  key={document.id}>
                  {`${document.abreviatura.toUpperCase()} - ${document.nombre}`}
                </option>
              ))}
            </select>
          </div>
          <div className='form-column'>
            <label htmlFor='documento'>Número de Documento</label>
            <input
              type='number'
              id='documento'
              name='Documento'
              value={formData.Documento}
              placeholder='Digite el número de documento'
              disabled
              required
            />
          </div>
        </div>
      </div>
      <div className='user-account-form-container'>
        <Shippingform
          title={"Datos de Envío"}
          enablePayment={true}
          enableShipmentType={true}
          data={formData}
          setData={setFormData}
        />
      </div>
    </form>
  );
};
