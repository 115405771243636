import { useState, useContext } from "react";
import { CartContext } from "../contexts"; // Ajusta la ruta según corresponda
import { capitalizeWords } from "../utils"; // Ajusta la ruta si es necesario

/**
 * Hook personalizado para manejar los datos de la orden.
 * @returns {[Object, Function]} - Un arreglo con los datos de la orden y una función para actualizarlos.
 * @example
 * const [orderData, setOrderData] = useOrderData();
 * console.log(orderData);
 * // => { Nombre: "Juan Pérez", Primer_Apellido: "Gómez", ... }
 * setOrderData({ Nombre: "Pedro", Primer_Apellido: "Ramírez", ... });
 * console.log(orderData);
 * // => { Nombre: "Pedro", Primer_Apellido: "Ramírez", ... }
 */
const useOrderData = () => {
  const { shipmentData, document: Documento } = useContext(CartContext);

  const {
    Tipo1,
    Nombre,
    Segundo_Nombre,
    Segundo_Nombre1,
    Primer_Apellido,
  } = shipmentData?.user || {};

  const DEFAULT_ADDRESS = {
    Codigo_Deapartamento: "",
    Codigo_Municipio: "",
    Direccion: "",
    Complemento_Direccion: "",
    Correo:"",
    Celular:"",
  };

  const [data, setData] = useState({
    Nombre: shipmentData && capitalizeWords(Nombre),
    Segundo_Nombre: shipmentData && capitalizeWords(Segundo_Nombre),
    Primer_Apellido: shipmentData && capitalizeWords(Primer_Apellido),
    Segundo_Apellido: shipmentData && capitalizeWords(Segundo_Nombre1),
    Tipo1: Tipo1?.toUpperCase(),
    Documento,
    ...DEFAULT_ADDRESS
  });
  console.log("datos de envio", data);

  return [data, setData];
};

export default useOrderData;
