/**
 * Capitaliza la primera letra de cada palabra en una cadena.
 * @param {string} str - La cadena de texto a capitalizar.
 * @returns {string} - La cadena con la primera letra de cada palabra en mayúscula y el resto en minúscula.
 * @example
 * const result = capitalizeWords("hola mundo");
 * console.log(result); 
 * // "Hola Mundo"
 */
export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Formatea un número como una cadena de moneda en pesos colombianos (COP).
 * @param {number} value - El valor numérico que se va a formatear.
 * @returns {string} - El valor formateado como moneda en pesos colombianos (COP).
 * @example
 * const formattedValue = moneyFormat(100000);
 * console.log(formattedValue); 
 * // "$100.000"
 */
export const moneyFormat = (value) => {
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};
