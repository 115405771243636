/**
 * Genera la URL para el pago en Wompi con los parámetros necesarios.
 * @param {Object} paymentDetails - Detalles del pago.
 * @param {string} paymentDetails.currency - La moneda del pago (por ejemplo, "COP").
 * @param {number} paymentDetails.amount - El monto del pago en unidades monetarias (sin multiplicar por 100).
 * @param {Object} signatureResponse - Respuesta que contiene la referencia y la firma de integridad.
 * @param {string} signatureResponse.reference - Referencia única del pago.
 * @param {string} signatureResponse.signature - Firma de integridad para la seguridad del pago.
 * @param {string} redirectUrl - La URL a la que redirigir después del pago.
 * @returns {string} - La URL completa con los parámetros de consulta.
 */
export const generateWompiUrl = ( paymentDetails, signatureResponse, redirectUrl = window.location.origin ) => {
  const url = new URL(process.env.REACT_APP_WOMPI_URL);

  url.searchParams.append("public-key", process.env.REACT_APP_PUBLIC_KEY);
  url.searchParams.append("currency", paymentDetails.currency);
  url.searchParams.append("amount-in-cents", Math.round((paymentDetails.amount) * 100));
  url.searchParams.append("reference", signatureResponse.reference);
  url.searchParams.append("signature:integrity", signatureResponse.signature);
  url.searchParams.append("redirect-url", redirectUrl);

  return url.toString();
};
