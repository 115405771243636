import React, { useContext } from "react";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { Link } from "react-router-dom";
import "./Checkout.css";

export const Checkout = () => {
  const { 
    setShipmentData,
    setDataRecord,
    shipmentData,
    dataRecord,
    setDiscount,
    setDocument
  } = useContext(CartContext);

  /**
   * Desactiva el proceso de pago y envío, restableciendo los datos relacionados.
   * Esta función realiza las siguientes acciones:
   * - Restablece el estado de `makePayment` a una cadena vacía.
   * - Restablece el estado de `shipmentData` a una cadena vacía.
   * - Establece el estado de `dataRecord` a `false`.
   * - Restablece el estado de `discount` a un arreglo vacío.
   * - Restablece el estado de `document` a una cadena vacía.
   */
  const deactivate = () => {
    setShipmentData('');
    setDataRecord(false);
    setDiscount([]);
    setDocument('');
  };

  return (
    <div className="berry-checkout-timeLine">
      <Link to="/carrito" className="berry-checkout-timeLineItem active" onClick={() => deactivate()}>
        <div className="berry-checkout-timeLineIcon">
          <i className="fa-solid fa-cart-shopping"></i>
        </div>
        <span className="berry-checkout-timeLineMessage">Carrito</span>
      </Link>
      <span className={`berry-checkout-timeLineDivider ${ shipmentData || dataRecord ? "active" : "" }`}></span>
      <div className={`berry-checkout-timeLineItem ${ shipmentData || dataRecord ? "active" : "" }`}>

        <div className="berry-checkout-timeLineIcon">
          <i className="fa-solid fa-user"></i>
        </div>
        <span className="berry-checkout-timeLineMessage">Datos</span>
      </div>
      <span className="berry-checkout-timeLineDivider"></span>
      <div className="berry-checkout-timeLineItem">
        <div className="berry-checkout-timeLineIcon">
          <i className="fa-solid fa-credit-card"></i>
        </div>
        <span className="berry-checkout-timeLineMessage">Pago</span>
      </div>
    </div>
  );
};
